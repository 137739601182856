<template>
    <div class="rounded-md px-1 relative w-full hover:bg-gray-50" :class="{'border': border}" @click="optionsOpen = true"> <!-- pb-1 -->

        <transition-group 
            enter-active-class="transform-gpu" enter-from-class="opacity-0 scale-0 ease-in duration-200" enter-to-class="opacity-100 scale-100 ease-out duration-300"
            leave-active-class="transform-gpu" leave-from-class="opacity-100 scale-100 ease-out duration-300" leave-to-class="opacity-0 scale-0 ease-in duration-200"
            tag="div" class="inline-flex flex-wrap w-full items-center" :class="wrapperClasses"> <!-- mr-1 mt-1 px-2.5 py-0.5 -->

            <i v-if="icon" :class="icon" @click="focusInput"></i>

            <!-- <div v-for="(tag, indx) in tagsAreHidden ? [] : tags" :key="`tag-input-tag-${tag.value || tag}-${indx}`" 
                class="inline-flex items-center h-6 pl-2 pr-1 mb-1 mr-1 rounded-md text-xs font-medium cursor-pointer"
                :class="[{'pr-2': props.disabled}, tagClasses]">
                {{ tagText(tag) }}
            </div> -->

            <i v-if="iconRight" :class="iconRight" @click="focusInput"></i>

        </transition-group>

        <!-- <Teleport to="body" > -->
            <!-- :disabled="!shouldTeleport" -->
            <transition
                enter-active-class="transform-gpu" enter-from-class="opacity-0 translate-y-2 ease-in duration-200" enter-to-class="opacity-100 translate-y-0 ease-out duration-300"
                leave-active-class="transform-gpu" leave-from-class="opacity-100 ease-out duration-300" leave-to-class="opacity-0 ease-in duration-200">
                <div :class="[z, {'hidden': dropdownHidden}]" class="fixed max-h-60 mt-0.5 overflow-y-auto rounded-md w-[320px] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    v-if="optionsOpen" ref="optionsDropdown">  <!-- mt-1 -->
                    <!-- z-10 -->

                    <div v-for="t in tenantOptions" :key="`tenants-input-options-${t._id}`" class="text-sm" @click="optionSelected(t)">
                        <!-- <span v-if="group.groupName && group.items.length" class="font-semibold px-3 pt-2 block">{{ group.groupName }}</span>
                        <div v-for="(item,indx) in group.items" :key="`group-${gindx}-option-${item.value || item}`" class="relative cursor-pointer select-none py-2 pl-3 pr-9"
                            :class="[selectedGroup == gindx && selectedIndx == indx ? 'bg-slate-300 ' : 'text-gray-900']" 
                            @click="addTag(item)" @mouseover="selectOption(gindx, indx)">
                            <span class="block truncate" v-if="searchTerm">
                                <span v-for="(oo,i) in (item.text||item).replace(new RegExp(searchTerm, 'gi'), (str) => `::${str}::`).split('::')" 
                                    :key="`optionMatch-${(item.value||item)}-${oo}-${i}`" :class="i == 1 ? 'font-bold' : ''">
                                    {{oo}}
                                </span>
                            </span>
                            <span class="block truncate" v-else>
                                {{item.text || item}}
                            </span>
                        </div> -->
                        {{ t.name }}
                    </div>

                </div>
            </transition>
        <!-- </Teleport> -->
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { EJSON } from 'bson'
import { useMainStore } from '../stores/main'
const mainStore = useMainStore()
const props = defineProps({
    emptyMsg: {type: String, default: 'Keine Treffer gefunden'},
    allowNew: {type: Boolean, default: false},
    placeholder: {type: String, default: 'bitte auswählen'},
    border: {type: Boolean, default: true},
    appendToBody: {type: Boolean, default: true},
    items: {type: Array, default: []},
    icon: {type: [String, Array], default: ''},
    iconRight: {type: String, default: ''},
    groups: {type: Array, default: []},
    reservedSystemTags: {type: Object, default: {}},
    modelValue: {type: Array, default: []},
    limit: {type: Number, default: 0},
    disabled: {type: Boolean, default: false},
    offset: {type: Object, default: {x: 0, y: 0}},
    tagsAreHidden: {type: Boolean, default: false},
    dropdownHidden: {type: Boolean, default: false},
    tagClasses: {type: String, default: 'bg-blue-100 text-sky-600'},
    xClasses: {type: String, default: 'text-blue-400 hover:bg-blue-200 hover:text-blue-500'},
    inputClasses: {type: String, default: ''},
    dotsHidden: {type: Boolean, default: false},
    wrapperClasses: {type: String, default: ''},
    z: {type: String, default: 'z-10'}
})
const emit = defineEmits(['update:modelValue'])

const tenants = ref(EJSON.parse(EJSON.stringify(props.modelValue)))

const selectedIndx = ref(-1)
const optionsOpen = ref(false)
const inputField = ref()

const tenantOptions = computed(() => {
    return [
        {_id: '', name: 'Alle Gruppen'},
        ...mainStore.selectedCustomer.tenants
    ]
})

const optionsDropdown = ref(null)
watch(optionsOpen, async(newState, oldState) => {
    setTimeout(() => {
        if(newState) {
            document.body.addEventListener('click', closePickerOutside)
            optionsDropdown.value.style['left'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().left}px
                 - ${(optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.left || 0)}px + ${props.offset.x}px
            )`;
            optionsDropdown.value.style['top'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().top}px
                 - ${((optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) + 
                 (optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.scrollTop || 0))}px - 0.5rem + ${props.offset.y}px
            )`;
        } else {
            document.body.removeEventListener('click', closePickerOutside)
        }
    }, 1);
})

const closePickerOutside = (event) => {
    // console.log(event.target, pickerDropdown.value?.contains(event.target));
    if(!optionsDropdown.value?.contains(event.target)) {
        optionsOpen.value = false
    }
}

const optionSelected = (t) => {
    if(t._id) {
        if(props.modelValue.map(m => m.toString()).includes(t._id.toString())) {
            emit('update:modelValue', [...props.modelValue.filter(m => m.toString() !== t._id.toString())])
        } else emit('update:modelValue', [...props.modelValue, t._id])
    } else {
        emit('update:modelValue', null)
    }
}

// const selectOption = (gindx, indx) => {
//     selectedGroup.value = gindx
//     selectedIndx.value = indx
// }
// const tagText = (tag) => {
//     let item = (props.groups.length ? props.groups.reduce((a,g) => a = [...a, ...g.items], []) : props.items).find(
//         i => (typeof tag === 'object' && !Array.isArray(tag) && !/^[0-9a-fA-F]{24}$/.test(tag.toString())) 
//             ? (Object.keys(i.value||i).length == Object.keys(tag).length && Object.keys((i.value||i)).every(k => (i.value||i)[k] === tag[k]))
//             : (i.value||i).toString() === (tag.toString())
//     )
//     return tagsKeyValue.value[tag] || item?.text || item || props.reservedSystemTags[tag] || tag
// }
/*
const keyPressed = (event) => {
    optionsOpen.value = true
    if(filteredItems.value.length == 0) selectedIndx.value = -1
    if(event.key == 'Enter' || event.key == ',' || event.key == 'Tab') {
        event.preventDefault()
        addTag(event)
    }
    if(event.key == 'Backspace') {
        removeLastTag(event)
    }
    if(event.key == 'ArrowDown') {
        if(selectedGroup.value == -1) selectedGroup.value = 0
        if(selectedGroup.value > groupedFilteredItems.value.length -1) {
            selectedGroup.value = 0
            selectedIndx.value = 0
        }
        selectedIndx.value++
        if(selectedIndx.value == groupedFilteredItems.value[selectedGroup.value].items.length) {
            if(groupedFilteredItems.value.length -1 > selectedGroup.value) {
                selectedGroup.value++
                selectedIndx.value = 0
            } else {
                selectedGroup.value = 0
                selectedIndx.value = 0
            }
        } else {
            if(selectedIndx.value >= filteredItems.value.length) selectedIndx.value = 0
        }
        optionsOpen.value = true
    }
    if(event.key == 'ArrowUp') {
        event.preventDefault()
        if(selectedGroup.value == -1) {
            selectedGroup.value = groupedFilteredItems.value.length - 1
            selectedIndx.value = groupedFilteredItems.value[selectedGroup.value] ? groupedFilteredItems.value[selectedGroup.value].items.length -1 : -1
        }
        selectedIndx.value--
        if(selectedIndx.value < 0) {
            if(selectedGroup.value == 0) {
                selectedGroup.value = groupedFilteredItems.value.length -1
                selectedIndx.value = groupedFilteredItems.value[selectedGroup.value].items.length -1
            } else {
                selectedGroup.value--
                selectedIndx.value = groupedFilteredItems.value[selectedGroup.value].items.length -1
            }
        } else {
            
        }
    }
    if(event.key == 'Escape') {
        selectedGroup.value = -1
        selectedIndx.value = -1
        markedForDelete.value = -1
        optionsOpen.value = false
    }
}
const addTag = (event) => {
    var val = null
    if(selectedIndx.value > -1) {
        if(selectedGroup.value > -1) {
            val = groupedFilteredItems.value[selectedGroup.value].items[selectedIndx.value]
        } else {
            val = filteredItems.value[selectedIndx.value]
        }
    } else {
        val = (props.allowNew ? searchTerm.value.trim() : '')
    }
    if(val && (val.value || val.length > 0)) {
        tags.value.push(val.value||val)
        selectedGroup.value = -1
        selectedIndx.value = -1
        markedForDelete.value = -1
        searchTerm.value = ''
        emit('update:modelValue', tags.value)
    }
}
const removeTag = (indx) => {
    tags.value.splice(indx, 1);
    markedForDelete.value = -1
    emit('update:modelValue', tags.value)
}
const removeLastTag = (event) => {
    if(searchTerm.value.length === 0) {
        if(markedForDelete.value == -1) {
            markedForDelete.value = tags.value.length - 1
        } else {
            removeTag(markedForDelete.value)
        }
    }
}
*/
</script>