import { createI18n } from 'vue-i18n'
import de from './de.json'
import en from './en.json'

export default createI18n({
    // datetimeFormats: {en: {...}},
    legacy: false,
    locale: 'de',
    fallbackLocale: 'en',
    missing(locale, key) {
        return key.split('.').pop()
    },
    messages: { de, en }
})