<template>
    <div class="rounded-md px-1 relative w-full" :class="{'border': border}"> <!-- pb-1 -->
        <div :key="'autocomplete-input-field'" class="flex-1"> <!-- min-w-[180px] --> <!-- mt-1 -->
            <input type="text" :placeholder="placeholder" 
                class="p-0 pl-1 h-6 border-none outline-none w-full focus:ring-0 focus:border-none focus:outline-none text-sm
                    dark:bg-inherit dark:placeholder:text-gray-300 dark:text-gray-300" 
                @keydown="keyPressed" @click="inputFocused" @blur="inputBlur" v-model="searchTerm" :disabled="props.disabled" />
        </div>
        <transition
            enter-active-class="transform-gpu" enter-from-class="opacity-0 translate-y-2 ease-in duration-200" enter-to-class="opacity-100 translate-y-0 ease-out duration-300"
            leave-active-class="transform-gpu" leave-from-class="opacity-100 ease-out duration-300" leave-to-class="opacity-0 ease-in duration-200">
            <div class="fixed z-10 max-h-60 mt-0.5 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                v-if="optionsOpen" ref="optionsDropdown" :class="maxWidth ? maxWidth : 'w-[320px]'">
                <div v-for="(item,indx) in filteredItems" :key="`option-${item.value || item}`" class="relative cursor-pointer select-none py-2 pl-3 pr-9"
                    :class="[selectedIndx == indx ? 'bg-slate-300 ' : 'text-gray-900']" 
                    @mouseover="selectOption(indx)" @click="selectItem(item)">
                    <slot :item="item">{{ item }}</slot>
                </div>
                <div v-if="!filteredItems.length" class="relative cursor-normal select-none py-2 pl-3 pr-9 text-gray-500">
                    {{ emptyMsg }}
                </div>
            </div>
        </transition>
        <!-- {{ tags }} -->
        <!-- {{ initTags }} -->
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { EJSON } from 'bson'
import { useMainStore } from '/src/stores/main'
const mainStore = useMainStore()
const props = defineProps({
    emptyMsg: {type: String, default: 'Keine Treffer gefunden'},
    allowNew: {type: Boolean, default: false},
    placeholder: {type: String, default: 'bitte auswählen'},
    maxWidth: {type: String, default: ''},
    border: {type: Boolean, default: true},
    fixedItems: {type: Array, default: []},
    appendToBody: {type: Boolean, default: true},
    modelValue: {type: String, default: ''},
    searchable: {type: Array, default: []},
    limit: {type: Number, default: 0},
    disabled: {type: Boolean, default: false},
    offset: {type: Object, default: {x: 0, y: 0}}
})
const emit = defineEmits(['update:modelValue', 'select'])
//const tags = ref(EJSON.parse(EJSON.stringify(props.modelValue)))
const selectedIndx = ref(-1)
const searchTerm = ref(props.modelValue || '')
const optionsOpen = ref(false)
const foundItems = ref([])

const optionsDropdown = ref(null)
watch(optionsOpen, async(newState, oldState) => {
    setTimeout(() => {
        if(newState) {
            // optionsDropdown.value.style.top = `calc(${optionsDropdown.value.getBoundingClientRect().top 
            //     - (optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) 
            //     - parseInt(getComputedStyle(optionsDropdown.value).marginTop.replace('px', ''))}px - 0.5rem)`;
            // optionsDropdown.value.style.position = 'fixed';
            optionsDropdown.value.style['left'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().left}px
                 - ${(optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.left || 0)}px + ${props.offset.x}px
            )`;
            optionsDropdown.value.style['top'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().top}px
                 - ${((optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) + 
                 (optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.scrollTop || 0))}px - 0.5rem
            )`;
            startSearch()
        }
    }, 1);
})

const filteredItems = computed(() => {
    return foundItems.value.filter(o => {
        if(typeof o === 'string') return o.toLowerCase().includes(searchTerm.value.toLowerCase())
        else return Object.keys(o).some(k => props.searchable.includes(k) && o[k].toLowerCase().includes(searchTerm.value.toLowerCase()))
    })
})

const inputFocused = (event) => {
    optionsOpen.value = true
}
const inputBlur = (event) => {
    // selectedIndx.value = -1
    optionsOpen.value = false
}
const selectOption = (indx) => {
    selectedIndx.value = indx
}
const selectItem = (item) => { // console.log(item);
    emit('select', item)
    setTimeout(() => { searchTerm.value = props.modelValue }, 100)
}

const startSearch = () => {
    if(props.modelValue || searchTerm.value.length >= 3) {
        console.log('should get items');
        foundItems.value = props.fixedItems // .map(item => ({ value: item.paragraph, text: item.paragraph }))
    }
}

const keyPressed = (event) => {
    optionsOpen.value = true
    if(filteredItems.value.length == 0) selectedIndx.value = -1
    startSearch()
    if(event.key == 'Enter' || event.key == ',' || event.key == 'Tab') {
        event.preventDefault()
        if(selectedIndx.value > -1 && selectedIndx.value < filteredItems.value.length) {
            selectItem(filteredItems.value[selectedIndx.value])
            optionsOpen.value = false
        }
    }
    if(event.key == 'ArrowDown') {
        selectedIndx.value++
        if(selectedIndx.value > filteredItems.value.length -1) {
            selectedIndx.value = 0
        }
        optionsOpen.value = true
    }
    if(event.key == 'ArrowUp') {
        event.preventDefault()
        selectedIndx.value--
        if(selectedIndx.value == -1) {
            selectedIndx.value = filteredItems.value.length -1
        }
    }
    if(event.key == 'Escape') {
        selectedIndx.value = -1
        optionsOpen.value = false
    }
}
</script>