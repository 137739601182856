<template>
    <div class="p-0 relative text-sm" @click="openPicker"> 
        <!-- py-4 px-2 -->
        
        <div v-if="!inline" class="cursor-pointer inline-block text-sm pickerLabel dark:bg-inherit dark:placeholder:text-gray-300 dark:text-gray-300" 
            :class="{'text-gray-500': !modelValue}" ref="pickerLabel">
            <!-- <i v-if="icon" :class="icon"></i> -->
            <i v-if="icon || iconOnly" class="" :class="icon || `fal fa-calendar fa-fw ${iconClass}`"></i>
            <span v-if="!iconOnly" :class="labelClasses">{{ modelValue 
                ? DateTime.fromJSDate(modelValue).minus({days: endDayInclusive ? 1 : 0}).toFormat(
                    labelFormat ? labelFormat : [`${showDate ? 'DD' : ''}`, `${showTime ? 'HH:mm' : ''}`].join(' ')
                ) 
                : (iconOnly ? '' : nullLabel)
            }}</span>
            <i v-if="iconRight" :class="iconRight"></i>
        </div>

        <teleport to="body" :disabled="inline">
            <transition
                enter-active-class="transform-gpu" enter-from-class="opacity-0 translate-y-2 ease-in duration-200" enter-to-class="opacity-100 translate-y-0 ease-out duration-300"
                leave-active-class="transform-gpu" leave-from-class="opacity-100 ease-out duration-300" leave-to-class="opacity-0 ease-in duration-200">
                
                <div class="bg-white cal overflow-hidden" v-if="pickerOpen || inline" ref="pickerDropdown"
                    :class="inline ? 'w-full' : 'fixed z-[60] w-[320px] shadow-lg border rounded-lg'">

                    <div class="text-center">
                        <div class="mt-2 items-center text-gray-900 px-3" :class="headerHidden ? 'hidden' : 'flex'">
                            <button type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500" @click="prev">
                                <i class="far fa-chevron-left fa-fw"></i>
                            </button>
                            <div class="flex-auto font-semibold">
                                <span class="cursor-pointer" @click="changeMode(displayMode == 'day' ? 'month' : 'year')">
                                    {{ displayMode == 'year' ? 'Jahr' : firstDayOfMonth.toFormat(displayMode == 'day' ? 'MMMM yyyy' : 'yyyy') }}
                                </span>
                            </div>
                            <button type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500" @click="next">
                                <i class="far fa-chevron-right fa-fw"></i>
                            </button>
                        </div>

                        <div class="border-y mt-2 divide-y" v-if="isNullAllowed || selectMode == 'time'">
                            <div class="flex items-center justify-center" v-if="selectMode == 'time'">
                                <i class="fal fa-clock fa-fw mr-2 text-xs"></i> 
                                <input type="time" :value="modelValue ? DateTime.fromJSDate(modelValue).toFormat('HH:mm') : '00:00'" 
                                    class="text-sm border-none outline-none px-0 focus:ring-0 focus:outline-none focus:border-none" 
                                    @input="(e) => selectTime(e.target.value)" ref="timeInput">
                            </div>
                            <button class="p-2 w-full text-gray-600 hover:bg-gray-100 text-sm datePickerNullButton" 
                                v-if="isNullAllowed" @click="selectNull">
                                {{ nullLabelInPicker || nullLabel }}
                            </button>
                        </div>

                        <div class="mt-2 grid grid-cols-7 text-xs font-semibold leading-6 text-gray-400" v-if="displayMode == 'day'">
                            <div>Mo</div>
                            <div>Di</div>
                            <div>Mi</div>
                            <div>Do</div>
                            <div>Fr</div>
                            <div>Sa</div>
                            <div>So</div>
                        </div>
                        <div class="isolate grid grid-cols-7 text-sm" v-if="displayMode == 'day'"
                            :class="inline ? '' : 'rounded-bl-lg rounded-br-lg'"> <!-- ring-1 ring-gray-200 -->
                                                        <!-- gap-px -->
                            <button v-for="(day, dayIdx) in days" :key="day" type="button" @click="selectDay(day)" 
                                :class="['py-1 rounded-md focus:z-10 group/btn', //hover:bg-gray-100
                                    day.hasSame(firstDayOfMonth, 'month') ? 'bg-white' : 'text-gray-200', 
                                    modelCalc && (day.hasSame(modelCalc, 'day') || day.hasSame(today, 'day')) && 'font-semibold', 
                                    modelCalc && day.hasSame(modelCalc, 'day') && 'text-white', 
                                    modelCalc && !day.hasSame(modelCalc, 'day') && day.hasSame(firstDayOfMonth, 'month') && !day.hasSame(today, 'day') && 'text-gray-900', 
                                    modelCalc && !day.hasSame(modelCalc, 'day') && !day.hasSame(firstDayOfMonth, 'month') && !day.hasSame(today, 'day') && 'text-gray-400', 
                                    modelCalc && day.hasSame(today, 'day') && !day.hasSame(modelCalc, 'day') && 'text-sky-700', 
                                    //dayIdx === days.length - 7 && selectMode !== 'time' && 'rounded-bl-lg', 
                                    //dayIdx === days.length - 1 && selectMode !== 'time' && 'rounded-br-lg'
                                ]">
                                <time :datetime="day" :class="['mx-auto flex h-7 w-7 items-center justify-center rounded', 
                                    modelCalc && day.hasSame(modelCalc, 'day') && day.hasSame(today, 'day') && 'bg-sky-500', 
                                    modelCalc && day.hasSame(modelCalc, 'day') && !day.hasSame(today, 'day') && 'bg-sky-500',
                                    (!modelCalc || (modelCalc && !day.hasSame(modelCalc, 'day'))) && 'group-hover/btn:bg-gray-100'
                                ]">{{ day.toFormat('dd').replace(/^0/, '') }}</time>
                            </button>
                        </div>

                        <div class="isolate mt-2 grid grid-cols-3 text-sm" v-if="displayMode == 'month'"
                            :class="inline ? '' : 'rounded-bl-lg rounded-br-lg'"> <!-- ring-1 ring-gray-200 -->

                            <button v-for="(month, monthIdx) in months" :key="month" type="button" @click="selectMonth(month)" 
                                :class="['py-1 hover:bg-gray-100 focus:z-10 bg-white text-gray-900 rounded-md',  
                                    modelValue && (month.hasSame(modelValue, 'month')) && 'font-semibold', 
                                    month.hasSame(today, 'month') && 'text-sky-500', 
                                    //monthIdx === months.length - 3 && selectMode !== 'time' && 'rounded-bl-lg', 
                                    //monthIdx === months.length - 1 && selectMode !== 'time' && 'rounded-br-lg'
                                ]">
                                <time :datetime="month" :class="['mx-auto flex h-7 w-7 items-center justify-center rounded']">
                                    {{ month.toFormat('MMMM').replace(/^0/, '') }}
                                </time>
                            </button>
                        </div>

                        <div class="isolate mt-2 grid grid-cols-5 text-sm" v-if="displayMode == 'year'"
                            :class="inline ? '' : 'rounded-bl-lg rounded-br-lg'"> <!-- ring-1 ring-gray-200 -->

                            <button v-for="(year, yearIdx) in years" :key="year" type="button" @click="selectYear(year)" 
                                :class="['py-1 hover:bg-gray-100 focus:z-10 bg-white text-gray-900 rounded-md',  
                                    modelValue && (year.hasSame(modelValue, 'year')) && 'font-semibold', 
                                    year.hasSame(today, 'year') && 'text-sky-500', 
                                    //yearIdx === years.length - 5 && selectMode !== 'time' && 'rounded-bl-lg', 
                                    //yearIdx === years.length - 1 && selectMode !== 'time' && 'rounded-br-lg'
                                ]">
                                <time :datetime="year" :class="['mx-auto flex h-7 w-7 items-center justify-center rounded']">
                                    {{ year.toFormat('yyyy').replace(/^0/, '') }}
                                </time>
                            </button>
                        </div>

                    </div>

                </div>
                
            </transition>
        </teleport>
    </div>
</template>

<script setup>
import { ref, watch, computed, onBeforeMount } from 'vue'
import { DateTime } from 'luxon'
import { useMq } from 'vue3-mq'
const mq = useMq()

const props = defineProps({
    modelValue: Date,
    initialMode: {type: String, default: 'day'},
    selectMode: {type: String, default: 'day'},
    showDate: {type: Boolean, default: true},
    showTime: {type: Boolean, default: true},
    isNullAllowed: {type: Boolean, default: false},
    nullLabel: {type: String, default: 'unbefristet'},
    nullLabelInPicker: {type: String, default: ''},
    iconOnly: {type: Boolean, default: false},
    iconClass: {type: String, default: ''},
    icon: {type: [String, Array], default: ''},
    iconRight: {type: String, default: ''},
    labelFormat: {type: String, default: ''},
    labelClasses: {type: String, default: ''}, 
    endDayInclusive: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    offset: {type: Object, default: {x: 0, y: 0}},
    headerHidden: {type: Boolean, default: false}, 
    maxWeeks: {type: Number, default: 0}, 
    inline: {type: Boolean, default: false}
})
const emit = defineEmits(['update:modelValue', 'update:mode', 'pickerToggled'])

// console.log(props.modelValue);

const displayMode = ref(props.initialMode)
const firstDayOfMonth = ref( (props.modelValue ? DateTime.fromJSDate(props.modelValue) : DateTime.local()).startOf('month') )
// const firstDayOfMonth = computed(() => {
//     return (props.modelValue ? DateTime.fromJSDate(props.modelValue) : DateTime.local()).startOf('month')
// })
const today = DateTime.local().startOf('day')
const firstDay = ref()
const lastDay = ref()
const days = ref([])
const months = ref([])
const years = ref([])
const timeInput = ref()
const pickerDropdown = ref()
const pickerOpen = ref(false)
const dropdownPosition = ref({top: 0, left: 0})
const pickerLabel = ref(null)

const modelCalc = computed(() => {
    return !props.endDayInclusive ? props.modelValue : DateTime.fromJSDate(props.modelValue).minus({days: 1}).toJSDate()
})

watch(pickerOpen, async(newState, oldState) => {
    setTimeout(() => {
        if(newState) {
            document.body.addEventListener('click', closePickerOutside)
            pickerDropdown.value.style['top'] = `${dropdownPosition.value.top + dropdownPosition.value.height + (props.offset.y || 0)}px`
            if(mq.xs) pickerDropdown.value.style['left'] = `${20}px`
            else pickerDropdown.value.style['left'] = `${dropdownPosition.value.left + (props.offset.x || 0)}px`
            emit('pickerToggled', true)
        } else {
            emit('pickerToggled', false)
            document.body.removeEventListener('click', closePickerOutside)
        }
    }, 1);
})

const closePickerOutside = (event) => {
    // console.log(event.target, pickerDropdown.value?.contains(event.target));
    if(!pickerDropdown.value?.contains(event.target)) {
        pickerOpen.value = false
    }
}

const buildMonth = () => {
    firstDay.value = firstDayOfMonth.value.startOf('week')
    lastDay.value = firstDayOfMonth.value.endOf('month').endOf('week')
    if(props.maxWeeks) {
        firstDay.value = DateTime.local().startOf('week')
        lastDay.value = firstDay.value.plus({weeks: 2})
    }
    let numDays = Math.ceil(lastDay.value.diff(firstDay.value, ['days']).values.days)
    days.value = [...Array(numDays).keys()].map(k => firstDay.value.plus({days: k}))
    months.value = [...Array(12).keys()].map(k => firstDayOfMonth.value.set({month: k+1}))
    // console.log(months.value);
}
const prev = () => {
    if(displayMode.value == 'year') {
        years.value = [...Array(20).keys()].map(k => years.value[0].minus({years: k+1})).reverse()
    } else {
        firstDayOfMonth.value = firstDayOfMonth.value.minus({[displayMode.value == 'day' ? 'months' : 'years']: 1})
        buildMonth()
    }
}
const next = () => {
    if(displayMode.value == 'year') {
        years.value = [...Array(20).keys()].map(k => years.value[years.value.length-1].plus({years: k+1}))
    } else {
        firstDayOfMonth.value = firstDayOfMonth.value.plus({[displayMode.value == 'day' ? 'months' : 'years']: 1})
        buildMonth()
    }
}
const selectTime = (time) => { // console.log(time);
    if(time) emit('update:modelValue', DateTime.fromJSDate(props.modelValue).set({hour: time.split(':')[0], minute: time.split(':')[1], second: 0}).toJSDate())
}
const selectDay = (day) => {
    day = day.set({year: day.year, month: day.month, day: day.day})
    if(props.selectMode == 'time') {
        day = day.set({hour: timeInput.value.value.split(':')[0], minute: timeInput.value.value.split(':')[1], second: 0})
    }
    if(props.endDayInclusive) {
        day = day.plus({days: 1})
    }
    emit('update:modelValue', day.toJSDate())
    // emit('update:modelValue', day.set({year: day.year, month: day.month, day: day.day}).plus({days: props.endDayInclusive ? 1 : 0}).toJSDate())
    pickerOpen.value = false
    if(!day.minus({seconds: (props.endDayInclusive ? 1 : 0)}).hasSame(firstDayOfMonth.value, 'month')) {
        setTimeout(() => {
            firstDayOfMonth.value = day.startOf('month')
            buildMonth()
        }, 100);
    }
    if(props.selectMode == 'time') {
        timeInput.value.focus()
    }
}
const selectMonth = (month) => {
    setTimeout(() => {
        if(props.selectMode == 'month') {
            emit('update:modelValue', month.startOf('month').toJSDate())
            pickerOpen.value = false
        } else {
            firstDayOfMonth.value = month.startOf('month')
            displayMode.value = 'day'
            buildMonth()
        }
    }, 10)
}
const selectYear = (year) => {
    setTimeout(() => {
        if(props.selectMode == 'year') {
            emit('update:modelValue', year.startOf('year').toJSDate())
            pickerOpen.value = false
        } else {
            firstDayOfMonth.value = year
            months.value = [...Array(12).keys()].map(k => firstDayOfMonth.value.set({month: k+1}))
            displayMode.value = 'month'
        }
    }, 10)
}
const selectNull = () => {
    emit('update:modelValue', null)
    pickerOpen.value = false
}
const changeMode = (mode) => {
    if(mode == 'year') {
        let decadeStart = today.set({year: `${today.year.toString().slice(0,3)}0`})
        years.value = [...Array(20).keys()].map(k => decadeStart.plus({years: k}))//.reverse()
    }
    displayMode.value = mode
}
// const togglePicker = () => {
//     pickerOpen.value = !pickerOpen.value
// }
const openPicker = (e) => {
    if(!props.disabled && !props.inline) {
        firstDayOfMonth.value = (props.modelValue ? DateTime.fromJSDate(props.modelValue) : DateTime.local()).startOf('month')
        dropdownPosition.value = (e ? e.target : pickerLabel.value).getBoundingClientRect()
        pickerOpen.value = true // console.log(e.clientX, e.clientY, e.target, rect.top, rect.left);
    }
}

buildMonth()

onBeforeMount(() => {
    if(props.initialMode == 'year') changeMode('year')
    if(props.initialMode == 'month') changeMode('month')
})

defineExpose({
    openPicker
})

</script>