<template>
    <div class="fixed inset-0 kdk-modal" :class="z" :show="open"> <!-- overflow-y-auto -->
        <div class="flex items-end justify-center text-center sm:block sm:p-0" :class="mq.xs ? 'absolute bottom-0 w-full' : 'pt-4 px-4 pb-20'">
                                <!-- min-h-screen -->

            <div ref="backdrop" class="fixed inset-0 transition-opacity kdk-modal-backdrop"
                :class="[
                    open ? 'ease-out duration-300 opacity-100' : 'ease-in duration-200 opacity-0',
                    mq.xs ? 'bg-white bg-opacity-90' : 'bg-gray-500 bg-opacity-75'
            ]"></div> <!-- @click="closeModal" -->

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <button v-if="closeX && open" type="button" class="fixed top-6 right-6 rounded-md text-gray-200 hover:text-white 
                focus:outline-none focus:ring-0 kdk-modal-close-btn" data-v-76999b0f="" @click="closeModal">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" data-v-76999b0f="">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" data-v-76999b0f=""></path>
                </svg>
            </button>
            
            <div class="relative inline-flex align-bottom bg-white text-left shadow-xl transform transition-all 
                sm:my-8 sm:align-middle max-h-[80vh] modal-wrapper"
                :class="[classes, mq.xs ? 'rounded-t-lg !w-full border-t' : 'rounded-lg', open ? 
                    'ease-out duration-300 opacity-100 translate-y-0 sm:scale-100 ' 
                    : 'ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 ']
                    " :style="[
                    width ? {'width': width} : '', 
                    mq.xs ? {'max-width': '100% !important;', 'min-width': '100% !important;'} : minWidth ? {'min-width': minWidth} : '',
                    minHeight ? {'min-height': minHeight} : '',
                    mq.xs ? {'max-width': '100% !important;', 'min-width': '100% !important;'} : maxWidth ? {'max-width': maxWidth} : '',
                    maxHeight ? {'max-height': maxHeight} : '',
                ]"> <!-- sm:max-w-lg -->

                <component :is="comp" :key="`comp-${key}`" v-bind="props.props" v-on="events" @close="closeModal"></component>            
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, defineAsyncComponent, h } from 'vue'
import { useMainStore } from '/src/stores/main'
import { useMq } from "vue3-mq"
const mq = useMq()
const props = defineProps({
    component: {type: String, default: ''}, // [Object, String]
    props: {type: Object, default: {}},
    events: {type: Object, default: {}},
    key: {type: String, default: ''},
    width: {type: String, default: ''},
    minWidth: {type: String, default: ''},
    minHeight: {type: String, default: ''},
    maxWidth: {type: String, default: ''},
    maxHeight: {type: String, default: ''},
    classes: {type: String, default: ''},
    closeX: {type: Boolean, default: false},
    closeBackdrop: {type: Boolean, default: false},
    z: {type: String, default: 'z-20'}
})
const open = ref(false)
const backdrop = ref()
const mainStore = useMainStore()
const MODULES = import.meta.glob('../**/*.vue');
var comp = defineAsyncComponent({
    loader: () => MODULES[`../${props.component.replace('components/', '')}.vue`.replace('..', '.')](),
    loadingComponent: h('div', {class: 'h-full w-full flex items-center justify-center text-gray-500 p-12'}, [h('i', {class: 'far fa-spinner-third fa-spin fa-fw'})]),
    errorComponent: h('div', {class: 'h-full w-full flex flex-col items-center justify-center p-4'}, [
        h('div', { class: 'text-center text-sm text-gray-500 leading-6' }, [
            h('strong', {class: 'block text-lg'}, `${navigator.onLine ? 'Eine neue Version ist verfügbar.' : 'Keine Internetverbindung.'}`),
            h('span', {class: 'block'}, 'Bitte aktualisiere die Ansicht und versuche es erneut.')
        ]),
        h('button', {
            class: 'bg-kd hover:bg-kd-hover text-white rounded-md px-4 py-2 mt-4 text-sm',
            onclick: () => window.location.href = `${window.location.origin}${window.location.pathname}?ts=${Date.now()}`
        }, ['Jetzt aktualisieren'])
    ]),
    onError(error, retry, fail, attempts) {
        fail()
    }
})
onMounted(() => {
    setTimeout(() => {
        open.value = true;
        if(props.closeBackdrop) backdrop.value.addEventListener('click', closeFromBackdrop)
    }, 100);
});
onBeforeUnmount(() => {
    if(props.closeBackdrop) backdrop.value.removeEventListener('click', closeFromBackdrop)
})
const closeModal = () => {
    open.value = false;
    setTimeout(() => { mainStore.modals.splice(-1) }, 300);
}
const closeFromBackdrop = (event) => {
    if(backdrop.value == event.target) closeModal()
}
</script>