<template>
    <div class="rounded-md px-1 relative w-full" :class="{'border': border}"> <!-- pb-1 -->

        <transition-group 
            enter-active-class="transform-gpu" enter-from-class="opacity-0 scale-0 ease-in duration-200" enter-to-class="opacity-100 scale-100 ease-out duration-300"
            leave-active-class="transform-gpu" leave-from-class="opacity-100 scale-100 ease-out duration-300" leave-to-class="opacity-0 scale-0 ease-in duration-200"
            tag="div" class="inline-flex flex-wrap w-full items-center" :class="wrapperClasses"> <!-- mr-1 mt-1 px-2.5 py-0.5 -->

            <i v-if="icon" :class="icon" @click="focusInput"></i>

            <div v-for="(tag, indx) in tagsAreHidden ? [] : tags" :key="`tag-input-tag-${tag.value || tag}-${indx}`" 
                class="inline-flex items-center h-6 pl-2 pr-1 mb-1 mr-1 rounded-md text-xs font-medium cursor-pointer"
                :class="[markedForDelete == indx ? 'bg-blue-300 text-sky-800' : '', {'pr-2': props.disabled}, tagClasses]">
                <!-- {{ tag.text || tag }} -->
                {{ tagText(tag) }}
                <button v-if="!props.disabled && !reservedSystemTags[tag] && !/^\d{4}\-\d{2}$/.test(tag)" type="button" 
                    class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none"
                    :class="xClasses" @click="removeTag(indx)">
                    <!-- <span class="sr-only">Remove small option</span> -->
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
                <span v-else class="mr-1"></span>
            </div>

            <div :key="'tag-input-field'" class="flex-1" :class="(tags.length && !tagsAreHidden) ? 'min-w-[50px] ml-1' : ''"> <!-- min-w-[180px] --> <!-- mt-1 -->
                <input type="text" :placeholder="(tags.length && !tagsAreHidden) ? (dotsHidden ? '' : '...') : placeholder" 
                    class="p-0 pl-1 h-6 border-none outline-none w-full focus:ring-0 focus:border-none focus:outline-none text-sm 
                        dark:bg-inherit dark:placeholder:text-gray-300 dark:text-gray-300" 
                    :class="[inputClasses, {'mb-1': !tags.length || tagsAreHidden}]" ref="inputField"
                    @keydown="keyPressed" @focus="inputFocused" @blur="inputBlur" v-model="searchTerm"
                    v-if="!props.disabled && (!props.limit || tags.length < props.limit)" 
                    /> <!-- pl-0 pr-0 --> <!-- @click="inputFocused" -->
                    <!-- h-6  -->
                    <!-- :style="tags.length ? {} : {'width': `${placeholder.length + 1}ch`}" -->
            </div>
            <i v-if="iconRight" :class="iconRight" @click="focusInput"></i>

        </transition-group>

        <!-- <Teleport to="body" > -->
            <!-- :disabled="!shouldTeleport" -->
            <transition
                enter-active-class="transform-gpu" enter-from-class="opacity-0 translate-y-2 ease-in duration-200" enter-to-class="opacity-100 translate-y-0 ease-out duration-300"
                leave-active-class="transform-gpu" leave-from-class="opacity-100 ease-out duration-300" leave-to-class="opacity-0 ease-in duration-200">
                <div :class="[z, {'hidden': dropdownHidden}]" class="fixed max-h-60 mt-0.5 overflow-y-auto rounded-md w-[320px] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    v-if="optionsOpen" ref="optionsDropdown">  <!-- mt-1 -->
                    <!-- z-10 -->

                    <div v-for="(group,gindx) in groupedFilteredItems" :key="`tag-input-${group.groupName}-options-${gindx}`" class="text-sm">
                        <span v-if="group.groupName && group.items.length" class="font-semibold px-3 pt-2 block">{{ group.groupName }}</span>
                        <div v-for="(item,indx) in group.items" :key="`group-${gindx}-option-${item.value || item}`" class="relative cursor-pointer select-none py-2 pl-3 pr-9"
                            :class="[selectedGroup == gindx && selectedIndx == indx ? 'bg-slate-300 ' : 'text-gray-900']" 
                            @click="addTag(item)" @mouseover="selectOption(gindx, indx)">
                            <span class="block truncate" v-if="searchTerm">
                                <span v-for="(oo,i) in (item.text || item.service || item).toString().replace(new RegExp(searchTerm, 'gi'), (str) => `::${str}::`).split('::')" 
                                    :key="`optionMatch-${(item.value || item)}-${oo}-${i}`" :class="i == 1 ? 'font-bold' : ''">
                                    {{oo}}
                                </span>
                            </span>
                            <span class="block truncate" v-else>
                                {{item.text || item}}
                            </span>
                        </div>
                    </div>
                    <!-- <div v-for="(item,indx) in filteredItems" :key="`option-${item.value || item}`" class="relative cursor-pointer select-none py-2 pl-3 pr-9"
                        :class="[selectedIndx == indx ? 'bg-slate-300 ' : 'text-gray-900']" 
                        @click="addTag(item)" @mouseover="selectOption(indx)">
                        <span class="block truncate" v-if="searchTerm">
                            <span v-for="(oo,i) in (item.text||item).replace(new RegExp(searchTerm, 'gi'), (str) => `::${str}::`).split('::')" 
                                :key="`optionMatch-${(item.value||item)}-${oo}-${i}`" :class="i == 1 ? 'font-bold' : ''">
                                {{oo}}
                            </span>
                        </span>
                        <span class="block truncate" v-else>
                            {{item.text || item}}
                        </span>
                    </div> -->

                    <div v-if="!filteredItems.length" class="relative cursor-normal select-none py-2 pl-3 pr-9 text-gray-500">
                        {{ emptyMsg }}
                    </div>
                </div>
            </transition>
        <!-- </Teleport> -->

        <!-- {{ tags }} -->
        <!-- {{ initTags }} -->

  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { EJSON } from 'bson'
import { useMainStore } from '../stores/main'
const mainStore = useMainStore()
const props = defineProps({
    emptyMsg: {type: String, default: 'Keine Treffer gefunden'},
    allowNew: {type: Boolean, default: false},
    placeholder: {type: String, default: 'bitte auswählen'},
    border: {type: Boolean, default: true},
    appendToBody: {type: Boolean, default: true},
    items: {type: Array, default: []},
    icon: {type: [String, Array], default: ''},
    iconRight: {type: String, default: ''},
    groups: {type: Array, default: []},
    reservedSystemTags: {type: Object, default: {}},
    onlyPickAndEmit: {type: Boolean, default: false},
    modelValue: {type: Array, default: []},
    limit: {type: Number, default: 0},
    disabled: {type: Boolean, default: false},
    offset: {type: Object, default: {x: 0, y: 0}},
    tagsAreHidden: {type: Boolean, default: false},
    dropdownHidden: {type: Boolean, default: false},
    tagClasses: {type: String, default: 'bg-blue-100 text-sky-600'},
    xClasses: {type: String, default: 'text-blue-400 hover:bg-blue-200 hover:text-blue-500'},
    inputClasses: {type: String, default: ''},
    dotsHidden: {type: Boolean, default: false},
    wrapperClasses: {type: String, default: ''},
    z: {type: String, default: 'z-10'}
})
const emit = defineEmits(['update:modelValue'])

const tags = ref(EJSON.parse(EJSON.stringify(props.modelValue)))
const tagsKeyValue = computed(() => {
    return mainStore.adminConfig?.tags ? [...mainStore.adminConfig.tags.lifeScopes, ...mainStore.adminConfig.tags.factorTags].reduce((a,tag) => a = {...a, [tag.id]: tag.name}, {}) : {}
})
// const options = ref(['Abcdefg', 'Xyzabcdefg', 'Abcdefgg', 'Xyzabcdefgg', 'Abcdefggg', 'Xyzabcdefggg', 'Abcdefgggg', 'Xyzabcdefgggg'])
const selectedGroup = ref(-1)
const selectedIndx = ref(-1)
const searchTerm = ref('')
const optionsOpen = ref(false)
const markedForDelete = ref(-1)
const inputField = ref()

const optionsDropdown = ref(null)
watch(optionsOpen, async(newState, oldState) => {
    setTimeout(() => {
        if(newState) {
            // optionsDropdown.value.style.top = `calc(${optionsDropdown.value.getBoundingClientRect().top 
            //     - (optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) 
            //     - parseInt(getComputedStyle(optionsDropdown.value).marginTop.replace('px', ''))}px - 0.5rem)`;
            // optionsDropdown.value.style.position = 'fixed';
            optionsDropdown.value.style['left'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().left}px
                 - ${(optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.left || 0)}px + ${props.offset.x}px
            )`;
            optionsDropdown.value.style['top'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().top}px
                 - ${((optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) + 
                 (optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.scrollTop || 0))}px - 0.5rem + ${props.offset.y}px
            )`;
        }
    }, 1);
})

const groupedFilteredItems = computed(() => {    
    return props.groups.length ? props.groups.map(
        g => ({ groupName: g.name, items: g.items.filter(
            o => (o.text||o).toString().toLowerCase().includes(searchTerm.value.toLowerCase()
        ) && !tags.value.map(t => (t.value||t).toString()).includes((o.value||o).toString())) })
    ).filter(g => g.items.length) 
    : [{ groupName: '', items: props.items.filter(
            o => (o.text||o).toString().toLowerCase().includes(searchTerm.value.toLowerCase()
        ) && !tags.value.map(t => (t.value||t).toString()).includes((o.value||o).toString())) }]
})

const filteredItems = computed(() => {
    return groupedFilteredItems.value.reduce((a,g) => a = [...a, ...g.items], [])
})
// const filteredItems = computed(() => {
//     return props.items.filter(
//         o => (o.text||o).toLowerCase().includes(searchTerm.value.toLowerCase()
//     ) && !tags.value.map(t => (t.value||t).toString()).includes((o.value||o).toString()))
// })

const inputFocused = (event) => {
    optionsOpen.value = true
}
const inputBlur = (event) => {
    // selectedIndx.value = -1
    optionsOpen.value = false
    markedForDelete.value = -1
}
const selectOption = (gindx, indx) => {
    selectedGroup.value = gindx
    selectedIndx.value = indx
}
const focusInput = () => {
    inputField.value.focus()
}
const tagText = (tag) => {
    let item = (props.groups.length ? props.groups.reduce((a,g) => a = [...a, ...g.items], []) : props.items).find(
        i => (typeof tag === 'object' && !Array.isArray(tag) && !/^[0-9a-fA-F]{24}$/.test(tag.toString())) 
            ? (Object.keys(i.value||i).length == Object.keys(tag).length && Object.keys((i.value||i)).every(k => (i.value||i)[k] === tag[k]))
            : (i.value||i).toString() === (tag.toString())
    )
    return tagsKeyValue.value[tag] || item?.text || item?.service || item || props.reservedSystemTags[tag] || tag
}

const keyPressed = (event) => {
    optionsOpen.value = true
    if(filteredItems.value.length == 0) selectedIndx.value = -1
    if(event.key == 'Enter' || event.key == ',' || event.key == 'Tab') {
        event.preventDefault()
        addTag(event)
    }
    if(event.key == 'Backspace') {
        removeLastTag(event)
    }
    if(event.key == 'ArrowDown') {
        if(selectedGroup.value == -1) selectedGroup.value = 0
        if(selectedGroup.value > groupedFilteredItems.value.length -1) {
            selectedGroup.value = 0
            selectedIndx.value = 0
        }
        selectedIndx.value++
        if(selectedIndx.value == groupedFilteredItems.value[selectedGroup.value].items.length) {
            if(groupedFilteredItems.value.length -1 > selectedGroup.value) {
                selectedGroup.value++
                selectedIndx.value = 0
            } else {
                selectedGroup.value = 0
                selectedIndx.value = 0
            }
        } else {
            if(selectedIndx.value >= filteredItems.value.length) selectedIndx.value = 0
        }
        optionsOpen.value = true
    }
    if(event.key == 'ArrowUp') {
        event.preventDefault()
        if(selectedGroup.value == -1) {
            selectedGroup.value = groupedFilteredItems.value.length - 1
            selectedIndx.value = groupedFilteredItems.value[selectedGroup.value] ? groupedFilteredItems.value[selectedGroup.value].items.length -1 : -1
        }
        selectedIndx.value--
        if(selectedIndx.value < 0) {
            if(selectedGroup.value == 0) {
                selectedGroup.value = groupedFilteredItems.value.length -1
                selectedIndx.value = groupedFilteredItems.value[selectedGroup.value].items.length -1
            } else {
                selectedGroup.value--
                selectedIndx.value = groupedFilteredItems.value[selectedGroup.value].items.length -1
            }
        } else {
            
        }
    }
    if(event.key == 'Escape') {
        selectedGroup.value = -1
        selectedIndx.value = -1
        markedForDelete.value = -1
        optionsOpen.value = false
    }
}
const addTag = (event) => {
    var val = null
    if(selectedIndx.value > -1) {
        if(selectedGroup.value > -1) {
            val = groupedFilteredItems.value[selectedGroup.value].items[selectedIndx.value]
        } else {
            val = filteredItems.value[selectedIndx.value]
        }
    } else {
        val = (props.allowNew ? searchTerm.value.trim() : '')
    }
    if(val && (val.value || val.length > 0)) {
        if(!props.onlyPickAndEmit) {
            tags.value.push(val.value||val)
        }
        selectedGroup.value = -1
        selectedIndx.value = -1
        markedForDelete.value = -1
        searchTerm.value = ''
        emit('update:modelValue', props.onlyPickAndEmit ? [val.value||val] : tags.value)
    }
}
const removeTag = (indx) => {
    tags.value.splice(indx, 1);
    markedForDelete.value = -1
    emit('update:modelValue', tags.value)
}
const removeLastTag = (event) => {
    if(searchTerm.value.length === 0) {
        if(markedForDelete.value == -1) {
            markedForDelete.value = tags.value.length - 1
        } else {
            removeTag(markedForDelete.value)
        }
    }
}
</script>