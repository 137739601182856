<template>
    <div class="rounded-md px-1 relative w-full" :class="{'border': border}"> <!-- pb-1 -->
        <div :key="'autocomplete-input-field'" class="flex-1"> <!-- min-w-[180px] --> <!-- mt-1 -->
            <input type="text" :placeholder="modelValue?.label || placeholder" :class="[textColor, textAlign, modelValue.label ? 'placeholder-sky-500' : '']"
                class="p-0 pl-1 h-5 mb-1 border-none outline-none w-full focus:ring-0 focus:border-none focus:outline-none text-sm
                    dark:bg-inherit dark:placeholder:text-gray-300 dark:text-gray-300" 
                @keydown="keyPressed" @click="inputFocused" @blur="inputBlur" v-model="searchTerm" :disabled="props.disabled" />
        </div>
        <transition
            enter-active-class="transform-gpu" enter-from-class="opacity-0 translate-y-2 ease-in duration-200" enter-to-class="opacity-100 translate-y-0 ease-out duration-300"
            leave-active-class="transform-gpu" leave-from-class="opacity-100 ease-out duration-300" leave-to-class="opacity-0 ease-in duration-200">
            <div class="fixed z-10 max-h-60 mt-0.5 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                v-if="optionsOpen" ref="optionsDropdown" :class="maxWidth ? maxWidth : 'w-[320px]'">
                <div v-for="(item,indx) in filteredItems" :key="`option-${item.value || item}-${indx}`" class="relative cursor-pointer select-none py-2 pl-3 pr-9"
                    :class="[selectedIndx == indx ? 'bg-slate-300 ' : 'text-gray-900']" 
                    @mouseover="selectOption(indx)" @click="selectItem(item)">
                    <!-- <slot :item="item">{{ item.text || item }}</slot> -->
                    <slot :item="item">{{ item }}</slot>
                </div>
                <div v-if="!filteredItems.length" class="relative cursor-normal select-none py-2 pl-3 pr-9 text-gray-500">
                    {{ modelValue?.label || emptyMsg }}
                </div>
            </div>
        </transition>
        <!-- {{ tags }} -->
        <!-- {{ initTags }} -->

        <!-- <pre class="mt-24">{{ debug }}</pre> -->
        <!-- <pre>{{ modelValue }}</pre> -->

  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { EJSON } from 'bson'
import { DateTime, Interval, Duration } from 'luxon'
import { RRule } from 'rrule'
import { useMainStore } from '/src/stores/main'
const mainStore = useMainStore()
const props = defineProps({
    emptyMsg: {type: String, default: 'irgendwann'},
    allowNew: {type: Boolean, default: false},
    placeholder: {type: String, default: 'irgendwann'},
    maxWidth: {type: String, default: ''},
    border: {type: Boolean, default: true},
    fixedItems: {type: Array, default: []},
    appendToBody: {type: Boolean, default: true},
    // modelValue: {type: String, default: ''},
    modelValue: {type: Object, default: {}},
    searchable: {type: Array, default: []},
    limit: {type: Number, default: 0},
    disabled: {type: Boolean, default: false},
    offset: {type: Object, default: {x: 0, y: 0}},
    textColor: {type: String, default: 'text-inherit'},
    textAlign: {type: String, default: 'left'}
})
const emit = defineEmits(['update:modelValue', 'select'])
//const tags = ref(EJSON.parse(EJSON.stringify(props.modelValue)))
const selectedIndx = ref(-1)
const searchTerm = ref('') // ref(props.modelValue || '')
const optionsOpen = ref(false)
const foundItems = ref([])

const foundComponents = ref([])
const foundComponentsStrings = ref([])
const foundComponentsTypes = ref([])
const foundComponentsJoined = ref({})
const foundComponentsRules = ref({})
// const condensed = ref([])
// const blocks = ref([])
// const matched = ref({})
const debug = ref({})

const matches = ref([])
const matchTypes = ref([])
const matchTypesStr = ref('')

if(props.modelValue?.label) searchTerm.value = props.modelValue.label

//let months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

let weekly = ['montags', 'dienstags', 'mittwochs', 'donnerstags', 'freitags', 'samstags', 'sonntags'].map((w,i) => ({ str: w, type: 'weekly', value: i % 7 }))
let weekdays = [
    'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag',
    'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'
].map((w,i) => ({ str: w, type: 'weekday', value: i % 7 }))
let months = [
    '', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember',
    '', 'Jan.', 'Feb.', '', 'Apr.', '', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dez.',
    '', 'Jan', 'Feb', '', 'Apr', '', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez',
    '', '', '', '', '', '', '', '', 'Sep.', '', '', '',
    '', '', '', '', '', '', '', '', 'Sep', '', '', '',
].map((w,i) => ({ str: w, type: 'month', value: i % 13 }))
let fixedDays = [{ str: 'heute', value: 0 }, { str: 'morgen', value: 1 }, { str: 'übermorgen', value: 2 }].map(w => ({ ...w, type: 'day' }))
let timeSuffixes = ['h', 'uhr'].map(w => ({ str: w, type: 'time-suffix' }))
let modifiers = ['Anfang', 'Mitte', 'Ende'].map(w => ({ str: w, type: 'modifier' }))
let at = ['am', 'im'].map(w => ({ str: w, type: 'point' }))
let until = ['bis zum', 'bis in', 'bis', 'in', 'zum', 'zu'].map(w => ({ str: w, type: 'until' }))
let from = ['ab', 'von'].map(w => ({ str: w, type: 'from' }))
let every = ['jeden', 'jeder', 'jede', 'jedes'].map(w => ({ str: w, type: 'every' }))
let freq = ['monatlich', 'wöchentlich', 'täglich'].map((w,i) => ({ str: w, type: 'freq', value: i+1 }))
let _this = ['diesen', 'diese'].map(w => ({ str: w, type: 'this' }))
let next = ['nächsten', 'nächste'].map(w => ({ str: w, type: 'next' }))
let grain = ['Tag', 'Woche', 'Monat', 'Jahr', 'Tage', 'Tagen', 'Wochen', 'Monaten', 'Jahren'].map(w => ({ str: w, type: 'grain' }))
let setpos = [{ str: 'letzter', value: -1 }, { str: 'letzten', value: -1 }].map(w => ({ ...w, type: 'setpos' }))
let daytimes = [
    { str: 'Früh', value: '06:00' }, { str: 'Morgens', value: '8:00' }, { str: 'Vormittag', value: '9:00' }, { str: 'Vormittags', value: '9:00' }, // { str: 'Morgen', value: '8:00' }
    { str: 'Mittag', value: '12:00' }, { str: 'Mittags', value: '12:00' }, { str: 'Nachmittag', value: '15:00' }, { str: 'Nachmittags', value: '15:00' },
    { str: 'Abend', value: '18:00' }, { str: 'Abends', value: '18:00' }, 
    { str: 'Nacht', value: '22:00' }, { str: 'Nachts', value: '22:00' }, 
].map(w => ({ ...w, type: 'daytime' }))


const optionsDropdown = ref(null)
watch(optionsOpen, async(newState, oldState) => {
    setTimeout(() => {
        if(newState) {
            optionsDropdown.value.style['left'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().left}px
                 - ${(optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.left || 0)}px + ${props.offset.x}px
            )`;
            optionsDropdown.value.style['top'] = `calc(
                ${optionsDropdown.value.getBoundingClientRect().top}px
                 - ${((optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) + 
                 (optionsDropdown.value.parentNode.closest('.overflow-y-auto')?.scrollTop || 0))}px - 0.5rem
            )`;
            startSearch()
        }
    }, 1);
})

const filteredItems = computed(() => {
    return foundItems.value
    // return foundItems.value.filter(o => {
    //     if(typeof o === 'string') return o.toLowerCase().includes(searchTerm.value.toLowerCase())
    //     else return Object.keys(o).some(k => props.searchable.includes(k) && o[k].toLowerCase().includes(searchTerm.value.toLowerCase()))
    // })
})

const inputFocused = (event) => {
    optionsOpen.value = true
}
const inputBlur = (event) => {
    // selectedIndx.value = -1
    optionsOpen.value = false
}
const selectOption = (indx) => {
    selectedIndx.value = indx
}
const selectItem = (item) => { // console.log(item);
    emit('select', item.value || item)
    console.log('SELECTED', item.value || item);
    setTimeout(() => { 
        // searchTerm.value = '' // props.modelValue 
        searchTerm.value = props.modelValue?.label || ''
    }, 100)
}


const numRange = (size, startAt = 0) => { // mod = 0
    return [...Array(size).keys()].map(i => i + startAt); // + mod) % mod
}

const startSearch = (txt) => {
    setTimeout(() => {
        //if(props.modelValue || searchTerm.value.length >= 0) {
        if(searchTerm.value.length >= 0) {
            console.log('should get items');
            let now = new Date()
            let matched = {}
            let condensed = ''
            let blocks = []

            foundComponents.value = searchTerm.value.split(/(\s+|\,|\-|\.)/ig).filter(str => str.trim()).filter(str => str != '.').map(
                str => [
                    ...months, ...weekdays, ...weekly, ...at, ...until, ...from, ...every, ...freq, ..._this, ...next, ...grain, 
                    ...daytimes, ...timeSuffixes, ...fixedDays, ...setpos, ...modifiers
                ].find(el => (new RegExp(`^${str}$`, 'ig').test(el.str || el))) 
                || (/(\d+\:\d+)|(\d+(h|uhr))/ig.test(str) ? { str: str, type: 'time' } : null) 
                || (/(^|\s+)\d+/ig.test(str) && !/\:/ig.test(str) ? { str: str, type: 'num' } : null) 
                || (str === '-' ? { str: str, type: 'range' } : null)
            ).map((str,i) => ({ ...str, indx: i })).filter(str => str.str)

            foundComponentsTypes.value = foundComponents.value.map(c => c.type)
            foundComponentsStrings.value = foundComponents.value.map(c => ({ str: c.str, indx: c.indx }))

            condensed = foundComponentsTypes.value.join(' ')
                .replaceAll('num time-suffix', 'time ')
                .replaceAll('num range time', 'time-range  ')
                .replaceAll('time range num', 'time-range  ')
                .replaceAll('time range time', 'time-range  ')
                .replaceAll('num range num', 'num-range  ')
                .replaceAll('num month num', 'date  ')
                .replaceAll('num month', 'date ')
                .replaceAll('num num time-range', 'date  time-range')
                .replaceAll('num num num', 'date  ')
                .replaceAll('num num', 'date ')
                .replaceAll('weekday range weekday', 'weekday-range  ')
                //.replaceAll(/date(\s+)range num/ig, 'date-range$1')
                //.replaceAll('num range date', 'date-range  ')
                //.replaceAll('date range date', 'date-range  ')
                //.replaceAll('num range num', 'num-range  ')
                //.replaceAll(/date(\s+)num-range/ig, 'date$1time-range')
                .replaceAll('every num grain', 'every interval grain')
                .replaceAll('every num weekday', 'every interval weekday')
                .replaceAll('num-range', 'time-range')
                .replaceAll(/time-range(\s+)month/ig, 'date-range$1')
            

            // console.log('CONDENSED', condensed, condensed.split(' ').map(c => c.toString()));

            blocks = condensed.split(' ').reduce(
                (a, s, i, arr) => {
                    if(s) {
                        let nextNonEmptyIndx = arr.findIndex((el,indx) => indx > i && el)
                        if(nextNonEmptyIndx == -1) nextNonEmptyIndx = arr.length
                        let items = foundComponents.value.slice(i, nextNonEmptyIndx)
                        a.push(items)
                    }
                    return a
                }, []
            )
            condensed = condensed.split(' ').filter(str => str).join(' ') // console.log(condensed); // console.log(blocks);
            debug.value.condensed = condensed
        
            matched = {
                months: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'month' ? blocks[i][0].value : ''], []).filter(str => str),
                'weekday-ranges': condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'weekday-range' ? blocks[i] : ''], []).filter(str => str),
                weekdays: [
                    ...condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'weekday' ? blocks[i][0].value : ''], []).filter(str => str !== ''),
                    ...condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'weekly' ? blocks[i][0].value : ''], []).filter(str => str !== ''),
                ],
                'time-ranges': condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'time-range' ? blocks[i].filter(b => b.type === 'time' || b.type === 'num') : ''], []).filter(str => str),
                times: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'time' ? blocks[i][0].str.replace('h', '') : ''], []).filter(str => str),
                daytimes: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'daytime' ? blocks[i][0].value : ''], []).filter(str => str),
                'date-ranges': condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'date-range' ? blocks[i] : ''], []).filter(str => str),
                dates: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'date' ? blocks[i] : ''], []).filter(str => str),
                fixed: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'day' ? blocks[i][0].value : ''], []).filter(str => !isNaN(str) && str !== '')[0],
                until: condensed.includes('until'),
                at: condensed.includes('point'),
                grain: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'grain' ? blocks[i][0].str : ''], []).filter(str => str),
                interval: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'interval' ? blocks[i][0].str : ''], []).filter(str => str),
                setpos: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'setpos' ? blocks[i][0].value || blocks[i][0].str : ''], []).filter(str => str).map(s => parseInt(s)),
                modifiers: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'modifier' ? blocks[i][0].str : ''], []).filter(str => str),
                freq: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'freq' ? blocks[i][0].value : ''], []).filter(str => str),
                next: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'next' ? blocks[i][0].str : ''], []).filter(str => str),
                _this: condensed.split(' ').reduce((a,str,i) => a = [...a, str === 'this' ? blocks[i][0].str : ''], []).filter(str => str),
                every: condensed.includes('every') || condensed.includes('weekly') || condensed.includes('freq'),
            }
            debug.value.matched = matched

            matched['weekday-ranges'].forEach(r => {
                let first = parseInt(r[0].value)
                let last = parseInt(r.slice(0).pop().value)
                if(first < last) {
                    numRange(last - first + 1, first).forEach(n => { if(!matched.weekdays.includes(n)) matched.weekdays.push(n) })
                } else if(last < first) {
                    // numRange((7 - first + 1) + last, first).forEach(n => { n = (n % 7 == 0) ? 7 : n % 7; if(!matched.weekdays.includes(n)) matched.weekdays.push(n) })
                    numRange((7 - first + 1) + last, first).forEach(n => { n = n % 7; if(!matched.weekdays.includes(n)) matched.weekdays.push(n) })
                }
            })
            delete matched['weekday-ranges']

            matched['date-ranges'].forEach(r => {
                // TODO:
            })

            matched['time-ranges'].slice(0,1).forEach(r => { matched.times = r.map(rr => rr.str.replace('h', '')).map(t => (t.includes(':')) ? t.split(':').map(tt => tt.padStart(2, '0')).join(':') : t.padStart(2, '0')) })
            delete matched['time-ranges']
            if(!matched.times.length && matched.daytimes.length) matched.times = matched.daytimes
            delete matched.daytimes
            matched.times = matched.times.map(t => (t.includes(':')) ? t.split(':').map(tt => tt.padStart(2, '0')).join(':') : t.padStart(2, '0'))
            if(matched.weekdays.length > 1) matched.every = true

            matched.dates = matched.dates.map(dt => {
                let d = DateTime.fromObject({ day: dt[0].str, month: dt[1].value || dt[1].str })
                if(dt.length > 2 && (dt[2]?.str?.length == 2 || dt[2]?.str?.length == 4)) {
                    d = d.set({ year: dt[2].str.length == 4 ? dt[2].str : `20${dt[2].str}` })
                }
                if(d < now) d = d.plus({ years: 1 })
                return d.toJSDate()
            })

            if(typeof matched.fixed !== 'undefined') matched.dates = [DateTime.local().plus({ days: matched.fixed }).startOf('day').toJSDate()]


            if(matched.every) {
                let rrule = { label: '' }
                if(matched.grain.length) {
                    if(/tag/ig.test(matched.grain[0])) rrule.freq = 3
                    if(/woche/ig.test(matched.grain[0])) rrule.freq = 2
                    if(/monat/ig.test(matched.grain[0])) rrule.freq = 1
                    if(/jahr/ig.test(matched.grain[0])) rrule.freq = 0
                    rrule.interval = (matched.interval.length) ? parseInt(matched.interval[0]) : 1
                } else if(matched.freq.length) {
                    rrule.freq = matched.freq[0]
                    rrule.interval = 1
                } else {
                    rrule.freq = 2
                    if(matched.interval.length) rrule.interval = parseInt(matched.interval[0])
                }
                if(matched.setpos.length) rrule.bysetpos = matched.setpos
                if(matched.weekdays.length) {
                    rrule.byweekday = matched.weekdays
                    rrule.dtstart = DateTime.fromObject({ weekday: matched.weekdays.sort((a,b) => parseInt(a) - parseInt(b)).filter(w => w > (now.getDay()+1))[0] })
                    if(rrule.dtstart < Date.now()) rrule.dtstart = rrule.dtstart.plus({ weeks: 1 })
                    rrule.dtstart = rrule.dtstart.toJSDate()
                    rrule.label += ` ${matched.weekdays.map(w => weekdays.slice(0).reverse().find(wd => wd.value == w).str).join(', ')}`
                }
                if(matched.times.length) {
                    let from = matched.times[0]
                    rrule.dtstart = (rrule.dtstart ? DateTime.fromJSDate(rrule.dtstart) : DateTime.local()).set({ 
                        hour: from.split(':')[0], minute: from.split(':')[1] || 0, second: 0
                    }).toJSDate()
                    rrule.byhour = parseInt(DateTime.fromJSDate(rrule.dtstart).toUTC().toFormat('HH')) //parseInt(from.split(':')[0])
                    rrule.byminute = parseInt(DateTime.fromJSDate(rrule.dtstart).toUTC().toFormat('mm'))
                    rrule.label += ` ${DateTime.fromJSDate(rrule.dtstart).toFormat('HH:mm')}`
                    let one = DateTime.fromISO(matched.times[0])
                    let two = matched.times.length > 1 ? DateTime.fromISO(matched.times[1]) : one.plus({ hours: 2 })
                    let dur = Interval.fromDateTimes(one, two).toDuration(["hours", "minutes"]).values
                    // rrule.duration = `${dur.hours.toString().padStart(2, '0')}:${dur.minutes.toString().padStart(2, '0')}`
                    rrule.duration = { hours: dur.hours, minutes: dur.minutes }
                } else {
                    rrule.dtstart = (rrule.dtstart ? DateTime.fromJSDate(rrule.dtstart) : DateTime.local()).startOf('day').toJSDate()
                }
                rrule.label = rrule.label.trim()
                foundItems.value = [{text: rrule.label, value: rrule}]
            }

            else if(matched.weekdays.length == 1 || matched.dates.length == 1) {
                let dates = []
                if(matched.weekdays.length == 1) dates = [DateTime.fromObject({ weekday: matched.weekdays[0]+1 }).plus({ weeks: (DateTime.fromObject({ weekday: matched.weekdays[0]+1 }) < Date.now() ? 1 : 0) }).toJSDate()]
                else dates = matched.dates.slice(0);
                if(matched.times.length) {
                    dates = dates.map(dt => ({
                        start: matched.until ? DateTime.local().startOf('hour').toJSDate() : DateTime.fromJSDate(dt).set({ hour: matched.times[0].split(':')[0], minute: matched.times[0].split(':')[1] || 0, second: 0 }).toJSDate(),
                        end: matched.times.length < 2 ? matched.until ? DateTime.fromJSDate(dt).set({ hour: matched.times[0].split(':')[0], minute: matched.times[0].split(':')[1] || 0, second: 0 }).toJSDate() : null : DateTime.fromJSDate(dt).set({ hour: matched.times[1].split(':')[0], minute: matched.times[1].split(':')[1] || 0, second: 0 }).toJSDate()
                    })).map(obj => ({ ...obj, end: obj.end || DateTime.fromJSDate(obj.start).plus({ hours: 2 }).toJSDate() }))
                        .map(obj => ({ ...obj, label: matched.until ? `bis ${DateTime.fromJSDate(obj.end).toFormat('ccc, dd. MMM HH:mm')}`: `am ${DateTime.fromJSDate(obj.start).toFormat('ccc, dd. MMM HH:mm')}` }))
                        //.map(obj => ({ ...obj, label: matched.until ? `bis ${DateTime.fromJSDate(obj.end).toFormat('ccc, dd. MMM HH:mm')}`: `am ${DateTime.fromJSDate(obj.start).toFormat('ccc, dd. MMM HH:mm')}` }))
                } else {
                    dates = dates.map(dt => ({ 
                        start: matched.until ? (DateTime.local().startOf('hour').toJSDate()) : dt, end: DateTime.fromJSDate(dt).endOf('day').toJSDate(),
                    })).map(obj => ({ 
                        ...obj, label: matched.until ? `bis ${DateTime.fromJSDate(obj.end).toFormat('ccc, dd. MMM')}`: `am ${DateTime.fromJSDate(obj.start).toFormat('ccc, dd. MMM')}` 
                    }))
                }
                foundItems.value = [...dates].map(obj => ({ text: obj.label, value: obj }))
            }

            else if(matched.times.length) {
                let dates = matched.times.map(t => DateTime.fromISO(t).plus({ days: (DateTime.fromISO(t) < Date.now() ? 1 : 0) }).toJSDate())
                if(dates.length > 1 && dates[0] > dates[1]) dates[1] = DateTime.fromJSDate(dates[1]).plus({ days: 1 }).toJSDate()
                foundItems.value = [{ start: dates[0], end: dates[1] || DateTime.fromJSDate(dates[0]).plus({ hours: 2 }).toJSDate() }]
                    .map(obj => ({ ...obj, label: `${DateTime.fromJSDate(obj.start).toFormat('ccc, dd. MMM HH:mm')}` }))
                    .map(obj => ({ text: obj.label, value: obj }))
            }

            else if(matched.months.length) {
                let d = DateTime.fromObject({ month: matched.months[0] })
                if(d < Date.now()) d = d.plus({ years: 1 })
                let until = { start: DateTime.local().startOf('hour').toJSDate(), end: d.endOf('day').toJSDate() }
                let at = { start: d.toJSDate(), end: d.endOf('month').toJSDate(), label: d.endOf('month').toFormat("'im' MMM") }
                if(matched.modifiers.length) {
                    if(/Ende/ig.test(matched.modifiers[0])) until.end = DateTime.fromJSDate(until.end).endOf('month').toJSDate()
                    if(/Mitte/ig.test(matched.modifiers[0])) until.end = DateTime.fromJSDate(until.end).set({ day: 15 }).toJSDate()
                    if(/Anfang/ig.test(matched.modifiers[0])) until.end = DateTime.fromJSDate(until.end).set({ day: 3 }).toJSDate()
                }
                until.label = `bis ${DateTime.fromJSDate(until.end).toFormat('dd. MMM')}`
                if(matched.until) foundItems.value = [until].map(obj => ({ text: obj.label, value: obj }))
                else if(matched.at) foundItems.value = [at].map(obj => ({ text: obj.label, value: obj }))
                else foundItems.value = [at, until].map(obj => ({ text: obj.label, value: obj }))
            }


            // TODO: 
            // KW num
            // 'bis nächsten monat'
            // 'bis nächste woche'
            // TOOD: 3. oktober 8-12


            else if(condensed === 'this grain' || condensed === 'next grain') {
                let dates = []
                let label = ''
                if(/woche/i.test(blocks[1][0].str)) {
                    dates = [{
                        start: DateTime.local().plus({weeks: matched.next.length ? 1 : 0}).startOf('week').toJSDate(), 
                        end: DateTime.local().plus({weeks: matched.next.length ? 1 : 0}).endOf('week').toJSDate()
                    }]
                    label = `KW ${DateTime.fromJSDate(dates[0].start).toFormat('WW')}`
                } else if(/monat/i.test(blocks[1][0].str)) {
                    dates = [{
                        start: DateTime.local().plus({months: matched.next.length ? 1 : 0}).startOf('month').toJSDate(), 
                        end: DateTime.local().plus({months: matched.next.length ? 1 : 0}).endOf('month').toJSDate()
                    }]
                    label = `im ${DateTime.fromJSDate(dates[0].start).toFormat('MMMM')}`
                }
                foundItems.value = [...dates].map(obj => ({ text: label, value: {...obj, label: label} }))
            }

            else if(condensed === 'until num') {
                let dates = []
                let label = ''
                if(blocks[0][0].str === 'in') {
                    dates.push(DateTime.local().plus({ days: blocks[1][0].str }).endOf('day').toJSDate())
                    label = `in ${blocks[1][0].str} Tagen`
                }
                if(blocks[0][0].str === 'bis') {
                    let d = DateTime.fromObject({ day: blocks[1][0].str })
                    if(d < Date.now()) d = d.plus({ months: 1 })
                    dates.push(d.toJSDate())
                    label = `bis ${d.toFormat('dd. MMM')}`
                }
                foundItems.value = [...dates].map(obj => ({ text: label, value: { end: obj } }))
            }
            
            else if(condensed === 'point num') {
                let dates = []
                let label = ''
                if(blocks[0][0].str === 'am') {
                    let d = DateTime.fromObject({ day: blocks[1][0].str })
                    if(d < Date.now()) d = d.plus({ months: 1 })
                    dates.push(d.toJSDate())
                    label = `am ${d.toFormat('dd. MMM')}`
                }
                foundItems.value = [...dates].map(obj => ({ text: label, value: { end: obj } }))
            }

            else if(condensed === 'point month') {
                let dates = []
                let label = ''
                let d = DateTime.fromObject({ month: matched.months[0] })
                if(d < Date.now()) d = d.plus({ years: 1 })
                dates.push({ start: d.toJSDate(), end: d.plus({ months: 1 }).toJSDate() })
                label = `im ${d.toFormat('MMMM')}`
                foundItems.value = [...dates].map(obj => ({ text: label, value: obj }))
            }

            else {
                foundItems.value = (searchTerm.value.length) ? [{ text: 'irgendwann', value: {end: null, label: 'irgendwann'} }] : []
            }

            return
        }
    }, 50)
}

const keyPressed = (event) => {
    optionsOpen.value = true
    if(filteredItems.value.length == 0) selectedIndx.value = -1
    startSearch()
    if(event.key == 'Enter' || event.key == 'Tab') { // || event.key == ','
        event.preventDefault()
        if(selectedIndx.value > -1 && selectedIndx.value < filteredItems.value.length) {
            selectItem(filteredItems.value[selectedIndx.value])
            optionsOpen.value = false
        }
    }
    if(event.key == 'ArrowDown') {
        selectedIndx.value++
        if(selectedIndx.value > filteredItems.value.length -1) {
            selectedIndx.value = 0
        }
        optionsOpen.value = true
    }
    if(event.key == 'ArrowUp') {
        event.preventDefault()
        selectedIndx.value--
        if(selectedIndx.value == -1) {
            selectedIndx.value = filteredItems.value.length -1
        }
    }
    if(event.key == 'Escape') {
        selectedIndx.value = -1
        optionsOpen.value = false
    }
    setTimeout(() => {
        if(!['Enter', 'Tab', 'ArrowDown', 'ArrowUp', 'Escape'].includes(event.key)) {
            if(filteredItems.value.length) selectedIndx.value = 0
        }
    }, 60)
}
</script>